import React from 'react';
import 'bulma/css/bulma.min.css';
import SocialIcon from '../SocialIcon';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_STAMP, APP_VERSION } from '../../version';



function Footer() {
    return (
        <footer className="footer is-fluid">
            <div className="content has-text-centered">
                <p>Jan Fath - 2024</p>
                <p>{APP_VERSION} - {APP_DATE_STAMP}</p>
                <SocialIcon icon={faLinkedin} link='https://www.linkedin.com/in/jan-fath-67a479164/' />
                <SocialIcon icon={faGithub} link='https://github.com/jandotde' />
                <SocialIcon icon={faEnvelope} link='mailto:hallo@janfath.de' />
            </div>
            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <a href="/impressum">Impressum</a>
                    </div>
                    <div className="level-item">
                        <a href="/datenschutz">Datenschutz</a>
                    </div>
                </div>
            </nav>
        </footer>
    );
}

export default Footer;